import {useI18n} from '@halp/foundation/i18n';
import {constructServerSideProps} from 'utils/ssr';
import {IFrameRenderer, Page} from '@halp/ui';
import {useUser, withUserProtectedRoute} from '@halp/foundation/Users';
import {useGreeting} from '@halp/util';
import style from './AdminIndex.module.css';

function AdminMetrics() {
	return (
		<div className={style.IframeContainer}>
			<IFrameRenderer
				src="/dashboard"
				className={style.Iframe}
				fullSize={false}
			/>
		</div>
	);
}

function AdminIndex() {
	const {t} = useI18n();
	const {user} = useUser();
	const greetingKey = useGreeting();

	return (
		<Page
			heading={t(`greeting.${greetingKey}`, {
				name: user?.first,
			})}
		>
			{user?.admin ? <AdminMetrics /> : null}
		</Page>
	);
}

export default withUserProtectedRoute(AdminIndex, {role: 'admin'});
export const getServerSideProps = constructServerSideProps();

import classNames from 'classnames';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {CSSVariants} from '@halp/util';
import {useBreakpoint} from '../Breakpoint';
import {Button} from '../Button';
import {Stack} from '../Stack';
import {Head} from '../Head';
import {Heading} from '../Typography';
import {Surface} from '../Surface';
import style from './Page.module.css';
import type {ButtonProps} from '../Button';
import type {HeadingType} from '../Typography';
import type {ReactNode} from 'react';

interface Props {
	children: ReactNode;
	head?: {
		title: string;
		meta?: string;
	};
	header?: ReactNode;
	mobileHeader?: {title: ReactNode; backPath: ButtonProps['href']};
	heading?: string | null;
	headingType?: HeadingType;
	statusMessage?: string | null;
	subHeading?: string | null;
	subHeadingType?: HeadingType;
	secondary?: ReactNode;
	sidebar?: ReactNode;
	sidebarSize?: 'wide';
	sidebarSide?: 'right' | 'left';
	margin?: 'sm' | 'lg' | 'none';
	className?: string;
}

export function Page({
	children,
	head,
	header,
	heading,
	headingType = 'h2',
	statusMessage,
	subHeading,
	subHeadingType = 'h4',
	secondary,
	sidebar,
	sidebarSize,
	sidebarSide,
	margin = 'lg',
	className,
	mobileHeader,
}: Props) {
	const mobile = useBreakpoint({max: 'md'});
	const headerMarkup = header ?? (
		<Heading type={headingType} spacing="lg">
			{heading}
		</Heading>
	);

	const status = statusMessage ? (
		<Surface color="yellow" shadow="none">
			<Heading type="h5" weight="semibold">
				{statusMessage}
			</Heading>
		</Surface>
	) : null;

	const topHeaderMarkup =
		mobile && mobileHeader ? (
			<Stack className={style.PageTopHeaderBarMobile}>
				<Button color="ghost" href={mobileHeader.backPath} icon={faAngleLeft} />
				<Heading type="h3" className={style.PageTopHeaderBarCenterTitle}>
					{mobileHeader.title}
				</Heading>
			</Stack>
		) : (
			headerMarkup
		);

	const headingMarkup =
		header || heading || mobileHeader ? (
			<div className={style.PageHeading}>
				{topHeaderMarkup}
				{status}
			</div>
		) : null;

	const subHeadingMarkup =
		subHeading || secondary ? (
			<div className={style.PageSubHeading}>
				<Heading type={subHeadingType}>{subHeading}</Heading>
				<div className={style.PageSubHeadingSecondary}>{secondary}</div>
			</div>
		) : null;

	const classes = classNames(
		sidebar != null && style.PageWithSidebar,
		CSSVariants(style, 'PageMargin', margin),
		CSSVariants(style, 'PageSidebarSize', sidebarSize),
		CSSVariants(style, 'PageSidebarSide', sidebarSide),
		!(heading || mobileHeader) && style.PageTopMargin,
		className,
	);

	return (
		<div className={classes}>
			<Head {...head} />
			{headingMarkup}
			{subHeadingMarkup}
			<div className={style.PageContent}>{children}</div>
			<div className={style.PageSidebar}>{sidebar}</div>
		</div>
	);
}
